(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('ApplicationController', ApplicationController);

    ApplicationController.$inject = ['$rootScope', '$state', 'AppService', 'Notification', 'ParseLinks'];

    function ApplicationController($rootScope, $state, AppService, Notification, ParseLinks) {
        var vm = this;

        vm.showLocation = showLocation;
        vm.searchByEmployee = searchByEmployee;
        vm.searchTerm = '';
        vm.getLocations = getLocations;

        vm.getLocations();


        function getLocations() {
            AppService.query({
                page: 0,
                size: 2000,
                search: vm.searchTerm
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.locations = data;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }
        }

        function showLocation(location) {
            var params = {
                location: location
            };

            $state.go("admin.location", params);
        }

        function searchByEmployee() {
            console.log("Search param "+vm.searchParam);

            var params = {
                'searchParam': vm.searchParam
            };

            AppService.searchByEmployeeOnServer(params, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.locations = data;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }

        }

    }
})();
